define("discourse/plugins/add_member_button/discourse/templates/connectors/user-profile-controls/add-member-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!log @outletArgs}}
  {{!log this}}
  {{!log this.model}}
  {{!log this.model.show_add_to_member_button}}
  {{!log this.model.username}}
  {{#if this.model.show_add_to_member_button}}
    <button type="button" class="btn btn-icon-text btn-primary" id="add-to-member-group-button" data-username="{{this.model.username}}" data-groupid="{{this.model.member_group_id}}">
      <svg class="fa d-icon d-icon-asterisk svg-icon svg-node" aria-hidden="true"><use xlink:href="#asterisk"></use></svg> Approve!
    </button>
  {{/if}}
  
  */
  {
    "id": "zUXnzTw7",
    "block": "[[[41,[30,0,[\"model\",\"show_add_to_member_button\"]],[[[1,\"  \"],[10,\"button\"],[14,0,\"btn btn-icon-text btn-primary\"],[14,1,\"add-to-member-group-button\"],[15,\"data-username\",[29,[[30,0,[\"model\",\"username\"]]]]],[15,\"data-groupid\",[29,[[30,0,[\"model\",\"member_group_id\"]]]]],[14,4,\"button\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,0,\"fa d-icon d-icon-asterisk svg-icon svg-node\"],[14,\"aria-hidden\",\"true\"],[12],[10,\"use\"],[14,\"xlink:href\",\"#asterisk\",\"http://www.w3.org/1999/xlink\"],[12],[13],[13],[1,\" Approve!\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"button\",\"svg\",\"use\"]]",
    "moduleName": "discourse/plugins/add_member_button/discourse/templates/connectors/user-profile-controls/add-member-button.hbs",
    "isStrictMode": false
  });
});