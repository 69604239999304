define("discourse/plugins/add_member_button/discourse/initializers/add-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "add-to-group-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.1", api => {
        api.onPageChange(url => {
          //console.log(url);
          document.querySelector("#add-to-member-group-button")?.addEventListener("click", function () {
            const username = this.dataset.username;
            const groupId = this.dataset.groupid;
            //console.log(username);
            //console.log(groupId)
            if (confirm("Are you sure you want to approve user " + username + "?")) {
              //{"usernames"=>"test123", "emails"=>"", "notify_users"=>"true", "id"=>"43"}
              (0, _ajax.ajax)(`/groups/${groupId}/members.json`, {
                type: "PUT",
                data: {
                  usernames: "" + username,
                  emails: "",
                  notify_users: "true"
                }
              }).then(response => {
                if (response.success) {
                  //alert("User added to ABCD group successfully!");
                  api.container.lookup('route:user').refresh();
                } else {
                  alert(`Failed to add user: ${response.error}`);
                }
              }).catch(err => {
                alert(`Error: ${err}`);
              });
            }
          });
        });
      });
    }
  };
  /*
  import { apiInitializer } from "discourse/lib/api";
  
  export default apiInitializer("0.4", (api) => {
    console.log(api);
    api.decorateWidget("post:after", (helper) => {
      console.log(helper);
      //const user = helper.attrs.user;
      //const currentUser = helper.currentUser;
  
      if(true){//(user && user.show_add_to_member_button && currentUser) {
        return helper.attach("button", {
          className: "btn btn-primary add-to-abcd-button",
          action: () => {
            const groupId = "member"; // The group name
            fetch(`/groups/${groupId}/members.json`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": helper.getCSRFToken(),
              },
              body: JSON.stringify({ user_id: user.id }),
            })
              .then((response) => {
                if (response.ok) {
                  helper.flash("User successfully added to group ABCD!", "success");
                } else {
                  response.json().then((data) => {
                    helper.flash(data.errors?.join(", ") || "Failed to add user.", "error");
                  });
                }
              });
          },
          label: "Add to Group Member",
        });
      }
    });
  });
  //*/
});